*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    transition: .5s ease-in-out;
    scroll-behavior: smooth;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* background: #f1f1f1;  */
    background: transparent;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C4C4C4; 
    border-radius: 1em;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
    border-radius: 1em;

  }

*::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

*::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

